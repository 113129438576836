// @flow
import React, { useState, useEffect } from "react";
import { Images } from "../../Themes";
import axios from "axios";

// import OptFillQuestion from "./OptFillQuestion";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

function AddAutoReminder(Props) {
  //   const [show, setShow] = useState(false);
  const labelArray = [
    "", // 0
    "Client Document", // 1
    "Client Admission", // 2
    "Client FileNote", // 3
    "Client Visa", // 4
    "Client Invoice", // 5
    "Client Email", // 6
    "Client Information", // 7
    "Client Tasks", // 8
    "Client Questionnaire", // 9
    "Client Tag", // 10
    "Client Employer", // 11
    "Client Jobs", // 12
    "Client Qualification", // 13
    "Client Balance", // 14
    "Client Chat", // 15
  ];

  // var logTypeName = labelArray[Props.logType];

  let clientprofileid = sessionStorage.getItem("clientprofileid");
  var profileData = JSON.parse(sessionStorage.getItem("profileData"));
  var token = localStorage.getItem("token");
  const reminderData = {
    subjectId: Props.profileId || clientprofileid || "",
    subjectName:
      Props.isEmployer || Props.fullName
        ? Props.fullName
        : Props.profileName || (profileData && profileData.fullName) || "",
    id: 0,
    reminderTypeId: Props.typeId || 1,
    reminderDescription: Props.description || "",
    reminderDetail: Props.title || "",
    isCompleted: false,
    reminderDate: Props.reminderDate,
    valueA: Props.valueA || "",
    expiryDate: Props.reminderDate,
  };
  // const baseURL = "https://beta-api.ezymigrate.co.nz/";
  // const baseURL = "https://uatapi.ezymigrate.co.nz/";
  const baseURL = "https://linuxapi-stage.ezymigrate.co.nz/";
  axios({
    method: "POST",
    url: `${baseURL}v1/reminder`,
    data: reminderData,
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {})
    .catch((err) => {});
}

export default AddAutoReminder;
