import React, { useState, useEffect } from "react";

function TimeCounter({ startTime, status, index }) {
  const [seconds, setSeconds] = useState(
    startTime.split(":").reduce((acc, time, index) => {
      return acc + parseInt(time) * Math.pow(60, 2 - index);
    }, 0)
  );
  const [isRunning, setIsRunning] = useState(status === 1 ? true : false); // Initially start the timer

  useEffect(() => {
    let intervalId;

    if (status === 1) {
      intervalId = setInterval(() => {
        // Update the seconds every second
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }

    // Cleanup function to clear the interval when the component unmounts or timer is stopped
    return () => clearInterval(intervalId);
  }, [status]); // Include isRunning in the dependency array

  useEffect(() => {
    setSeconds(
      startTime.split(":").reduce((acc, time, index) => {
        return acc + parseInt(time) * Math.pow(60, 2 - index);
      }, 0)
    );
  }, [startTime]);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div>
      {/* <h1>Time Counter</h1> */}
      <div>
        <span>{hours < 10 ? "0" + hours : hours}</span>:
        <span>{minutes < 10 ? "0" + minutes : minutes}</span>:
        <span>
          {remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds}
        </span>
      </div>
      {/* <button onClick={handleToggleTimer}>
        {isRunning ? "Stop" : "Start"}
      </button> */}
    </div>
  );
}

export default TimeCounter;
